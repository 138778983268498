import React from 'react';
import sc from './Subscription.module.scss';

import { SUBSCRIPTION_TABLE_HEADER_TITLES } from 'constants';
import TableHead from 'Components/TableHeader/TableHead';
import { Table } from 'react-bootstrap';
import moment from 'moment';

const SubscriptionTable = ({ invoices, handleInvoiceDownload }) => {
  return (
    <>
      <Table
        className={`table-fixed ${sc.subscriptionTable} table-sticky`}
        hover
        responsive="lg"
      >
        <TableHead TableHeadings={SUBSCRIPTION_TABLE_HEADER_TITLES} />
        <tbody>
          {invoices?.map((item) => {
            return (
              <tr key={item.id} style={{ cursor: 'pointer' }}>
                <td>
                  <div className={sc.columnContent}>
                    <span className={`${sc.text} ${sc.invoiceFile}`}>
                      {item.number} -{' '}
                      {moment.unix(item.created).format('MMM YYYY')}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={sc.columnContent}>
                    <span className={sc.text}>
                      {moment.unix(item.created).format('DD MMM, YYYY')}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={sc.columnContent}>
                    <span className={sc.status}>
                      <img src="/Images/check_green.svg" alt="greencheck" />
                      {item.paid ? 'Paid' : 'Pending'}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={sc.columnContent}>
                    <span className={sc.text}>
                      USD ${(item.amount_paid / 100).toFixed(2)}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={sc.columnContent}>
                    <span className={sc.text}>{item.plan_name}</span>
                  </div>
                </td>
                <td>
                  <div className={sc.columnContent}>
                    <span
                      className={`table_btn`}
                      onClick={() =>
                        handleInvoiceDownload(
                          item.id,
                          item.number,
                          item.hosted_invoice_url,
                        )
                      }
                    >
                      Send Email
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default SubscriptionTable;
