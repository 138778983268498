import React, { useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { ErrorMessage, Formik } from 'formik';
import { error as renderError } from 'helpers/toaster.js';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';
import { createDuplicateDocument } from 'services/documentService';

function DuplicateDocumentModal(props) {
  const [showForm, setShowForm] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const [duplicateDocument, setDuplicateDocument] = useState({});
  const { templateId } = useParams() || {};
  const dispatch = useDispatch();

  const initialValues = {
    title: props.document?.title,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
  });

  const handleSubmit = async ({ title }) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Duplicating!',
          description: 'Please wait...',
        }),
      );
      const res = await createDuplicateDocument(props.document?.id, title);

      dispatch(setLoader({ isLoading: false }));
      setSuccessMessage(res?.data?.message);
      setDuplicateDocument(res?.data?.document);
      setShowForm(false);
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  if (!showForm) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header className={'pb-0 border-0'} closeButton>
          <Modal.Title
            className="Modal-Title"
            id="contained-modal-title-vcenter"
          >
            Duplicate Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'d-flex flex-column p-4'}>
          <div>
            {successMessage}{' '}
            {templateId ? (
              <Link
                to={`/editor/document/${duplicateDocument.id}/template/${templateId}`}
                target="_blank"
              >
                Click here to edit
              </Link>
            ) : (
              <Link
                to={`/freestyle-editor/document/${duplicateDocument.id}`}
                target="_blank"
              >
                Click here to edit
              </Link>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  if (showForm) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={'pb-0 border-0'} closeButton>
          <Modal.Title
            className="Modal-Title"
            id="contained-modal-title-vcenter"
          >
            Duplicate Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'d-flex flex-column p-4'}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {({ values, isValid, dirty }) => {
              return (
                <form
                  className="d-flex flex-column"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <FormikTextField
                    name="title"
                    type="text"
                    placeholder="File name"
                    Label="File name *"
                    classList={'input'}
                  />
                  <ErrorMessage name="title">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                  <BSButton
                    ButtonText="Duplicate"
                    classList="w-100 mt-4 secondary_btn"
                    variant="dark"
                    disabled={!isValid && !dirty}
                    onClick={() => handleSubmit(values)}
                  ></BSButton>
                </form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DuplicateDocumentModal;
