import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';
import { stringify } from 'qs';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
// TODO: Check this Route
export const getProjects = (searchTerm, type, page) => {
  const queryParams = {
    type,
    'search[name_cont]': searchTerm,
    page,
  };

  return getRequest(`projects?${stringify(queryParams)}`);
};

export const getProject = (projectId) => {
  return getRequest(`projects/${projectId}`);
};

export const getProjectFolders = (projectId) => {
  return getRequest(`projects/${projectId}/folders`);
};

export const getProjectIntegrations = (projectId) => {
  return getRequest(`projects/${projectId}/integrations`);
};

export const getProjectUsers = (projectId) => {
  return getRequest(`projects/${projectId}/project_users`);
};

export const getRecentProjects = () => {
  return getRequest(`projects/recent`);
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const createProject = ({ body }) => {
  return postRequest('projects', {
    project: {
      ...body,
    },
  });
};

export const createProjectUser = (projectId, body) => {
  return postRequest(`projects/${projectId}/project_users`, body);
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
export const updateProject = ({ projectId, body }) => {
  return putRequest(`projects/${projectId}`, {
    project: body,
  });
};

// TODO: projects/:projectId/generate_style_guide

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
// TODO: projects/:projectId

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
export const deleteProject = (projectId) => {
  return deleteRequest(`projects/${projectId}`);
};

// TODO: Check this Route
export const deleteProjectUser = (projectId, userId) => {
  return deleteRequest(
    `projects/${projectId}/project_users/remove?project_user[user_id]=${userId}`,
  );
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const generateHeadlines = (body) => {
  return postRequest(`tasks/generate_headlines`, body);
};

export const generateBriefs = (body) => {
  return postRequest(`tasks/generate_briefs`, body);
};

export const saveBriefs = (body) => {
  return postRequest(`tasks`, body);
};
